






























import './scss/BaseSidebarQrCodes.scss';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BaseSidebarQrCodes extends Vue {}
